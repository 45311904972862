import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, BackTop, Button } from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import UseCaseSider from "../../components/useCases/use-cases-sider";

const UseCaseRemoteWorking = ({}) => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Remote Working</title>
      <meta
        name="description"
        content="DayViewer Team Room will help your business work remotely by enabling a shared calendar and task management system integrated with other productivity tools. Better scheduling online for you and your team."
      />
    </Helmet>
    <Layout>
      <CommonHeader />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <UseCaseSider />

        <Layout className="guide-body">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1>Remote Working Tasks Calendar Planner</h1>

            <Button className="homepage-signup-btn">
              <a
                href="#"
                onClick={() =>
                  window.open("https://app.dayviewer.com/auth/signup", "_self")
                }
              >
                Sign Up
              </a>
            </Button>
          </div>

          <Row>
            <div>
              <h2>Team Calendar Planner for Remote Working</h2>
            </div>
            <Col>
              <p>
                If your business is considering, or is currently working
                remotely and are looking to do so more efficiently, DayViewer
                could be a good fit for your remote work team.
              </p>

              <p>
                Effective remote work requires clarity in communication of team
                tasks. DayViewer helps your team by giving a centralized
                platform from which they can see clearly how each day looks and
                what they need to do.
              </p>

              <p>
                DayViewer Team Rooms are efficient and effective and takes
                little to no training to operate. Essentially you will just be
                adding tasks as entries in a calendar directly.
              </p>
              <p>
                Consolidate many other tools and apps by using DayViewer. One
                simple team calendar and task management system rolled up into
                one easy to use calendar planner system.
              </p>

              <p>
                Assign tasks and team roles useful for teams of any size.
                Suitable for for remote and distributed projects and internal
                teams. Team members also have roles which allow management and
                organization of tasks depending on the member or employee role.
              </p>

              <p>
                If you are managing a team or tasks for others as well as
                yourself, you can plan tasks in advance - adjust as necessary as
                often as required.
              </p>

              <p>
                Organize with labels - if your team work on multiple projects
                simultaneously, you can still use just one Team Room calendar
                system as long as the core team members are the same. Everything
                shows on the team calendar and is easily filterable.
              </p>

              <p>
                Remote working is easy, flexible and empowering for your team.
                It empowers your team as a task oriented one and reduces the
                need for progress meetings. Everything is easier to see who is
                doing what and by when. You can also comment on tasks and other
                entries, enabling highly focussed communications.
              </p>

              <p>
                There are many fully distributed teams operating multi million
                dollar turnover startups in the world - and this way of working
                is increasingly being adopted month on month.
              </p>

              <p>
                If you need to meet, use 3rd party additional communication or
                video-conferencing apps to make it feel like you are in one
                room. DayViewer does not have these features so you are able to
                use your favoured products. But you can use DayViewer to
                coordinate and manage once your virtual meetings have actions.
              </p>

              <p>
                Other major advantages are that it saves on office rent &amp;
                rates, commuting time and costs and can increase your personal
                time. Working remotely with DayViewer means working together
                with your team and coworkers - but with flexibility, efficiency
                and stress free!
              </p>

              <p>Get more work done remotely - but together with DayViewer!</p>
              <div
                style={{
                  textAlign: "center",
                  padding: 20,
                }}
              >
                <div style={{ marginTop: 10 }}>
                  <iframe
                    width="100%"
                    height="400"
                    src="https://www.youtube.com/embed/alX8wuHDrvo?rel=0&mute=1"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen="allowfullscreen"
                  ></iframe>
                </div>
              </div>
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default UseCaseRemoteWorking;
